/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { uniqueId, lowerCase } from 'lodash'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, ListItem, Pill } from '../components/elements'

const Projects = ({ data }) => {

  const page = data.prismic.projects
  const projects = data.prismic.allProjects

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={["full"]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
              margin: 3
            }}>
              <Flex justifyContent="flex-end" pb={4}>
                <Pill className="water" sx={{ p: '1em', mx: '0.5em' }} />&nbsp;Water
                <Pill className="wastewater" sx={{ p: '1em', mx: '0.5em' }} />&nbsp;Wastewater
              </Flex>
              <ul sx={{
                display: 'flex',
                flexWrap: "wrap",
                justifyContent: 'space-between'
              }}>
                {projects.edges.map(({ node: project }) => (
                  <ListItem key={`${uniqueId('project_id_')}`} sx={{ width: '100%', display: ['block', 'block', 'flex'] }}>

                    <Flex>
                      <Pill
                        className={`${lowerCase(project.project_type)}`} sx={{ mr: '1em', px: "0.5em", lineHeight: "0.5", height: '12px', width: '12px', alignSelf: 'center' }} />

                      <Link to={`projects/${project._meta.uid}`} sx={{ display: 'block' }}> {RichText.asText(project.title)} </Link>
                    </Flex>

                    <Box mx={['1.5em', '1.5em', 0]}>
                      {project.project_satus}
                    </Box>
                  </ListItem>
                ))}

              </ul>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Projects


export const query = graphql`
query projectsQuery {
  prismic {
    projects(lang: "en-us", uid: "projects") {
      title
      content
      meta_title
      meta_description
      _linkType
    }
    allProjects(sortBy: meta_firstPublicationDate_ASC) {
      edges {
        node {
          title
          project_type
          project_satus
          _meta {
            uid
          }
        }
      }
    }
  }
}
`